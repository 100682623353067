@use 'src/styles/mixins/editor';
@use 'src/styles/variables/colors';
@use 'src/styles/variables/sizes';

$image-max-width: 300px;
$toolbar-border: solid colors.$gray-200;
$toolbar-border-width: 0 0 1px 0;
$toolbar-action-button-padding: sizes.$size-8;
$toolbar-action-button-hover-background: colors.$gray-200;
$toolbar-action-button-hover-border-radius: sizes.$size-4;
$toolbar-action-box-margin: 0 sizes.$size-18 0 sizes.$size-12;

$content-font-size: sizes.$size-12;
$content-border: unset;

/* prettier-ignore */
.p-editor-container {
  @include editor.toolbar($toolbar-border, $toolbar-border-width);
  @include editor.toolbar-action($toolbar-action-button-padding);
  @include editor.toolbar-action-hover($toolbar-action-button-hover-background, $toolbar-action-button-hover-border-radius);
  @include editor.toolbar-action-picker-label();
  @include editor.toolbar-action-box($toolbar-action-box-margin);
}

.p-editor-content.ql-container {
  @include editor.content($content-border, $font-size: $content-font-size);
}
