@use 'src/styles/mixins/tables';
@use 'src/styles/variables/sizes';
@use 'src/styles/variables/colors';

$_background-color: colors.$white !default;
$_background-color-hover: colors.$gray-100 !default;
$_header-cell-font-size: sizes.$size-14 !default;
$_action-button-padding: sizes.$size-4 0 !default;

.p-datatable-table {
  @include tables.body(
    $background-color: $_background-color,
    $background-color-hover: $_background-color-hover
  );
  @include tables.header-cells($font-size: $_header-cell-font-size);
  @include tables.sortable-cells();
  @include tables.action-cells($action-button-padding: $_action-button-padding);
  @include tables.select-cells();
}
