@use 'src/styles/variables/colors';
@use 'src/styles/mixins/menubar';

$_menubar-border: none;
$_menubar-background-color: none;

.p-menubar {
  @include menubar.content(
    $border: $_menubar-border,
    $background-color: $_menubar-background-color
  );

  @include menubar.item-link(
    $text-color: colors.$dark-500,
    $hover-text-color: colors.$dark-700
  );
}
