/* montserrat-100 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-200 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-300 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-800 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-100italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-100italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-900 - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-200italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-200italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-300italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-300italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-500italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-500italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-600italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-600italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-700italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-700italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-800italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-800italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* montserrat-900italic - latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/montserrat/montserrat-v25-latin-ext-900italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
