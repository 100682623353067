@mixin content(
  $item-border-radius,
  $item-outline-color,
  $item-text-color,
  $item-text-highlight-color,
  $item-text-hover-color,
  $item-padding,
  $item-font-weight: normal
) {
  .p-panelmenu-header {
    .p-panelmenu-header-content {
      border-color: transparent;
      background-color: transparent;

      .p-panelmenu-header-action {
        padding: $item-padding;
        font-weight: $item-font-weight;
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-panelmenu-header-content {
        border-bottom-right-radius: $item-border-radius;
        border-bottom-left-radius: $item-border-radius;
        border-color: transparent;
        background-color: transparent;

        .p-panelmenu-header-action {
          color: $item-text-highlight-color;
        }
      }
    }

    &:not(.p-highlight):not(.p-disabled),
    &.p-highlight:not(.p-disabled) {
      &:hover {
        .p-panelmenu-header-content {
          background: transparent;
          border-color: transparent;

          .p-panelmenu-header-action {
            color: $item-text-hover-color;
          }
        }
      }
    }
  }
}
