@mixin content($border, $background-color) {
  background: $background-color;
  border: $border;
  padding: 0;
}

@mixin item-link($text-color, $hover-text-color) {
  .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: $text-color;

    .p-menuitem-text {
      color: inherit !important;
    }

    &:not(.p-disabled):hover {
      background-color: rgb(0 0 0 / 10%);
      color: $hover-text-color;
    }
  }
}
