@mixin toolbar($border: null, $border-width: null) {
  .ql-toolbar {
    border: $border;
    border-width: $border-width;
  }
}

@mixin toolbar-action($padding: null) {
  .ql-toolbar .ql-picker {
    cursor: pointer;
  }

  .ql-toolbar button,
  .ql-toolbar .ql-color-picker .ql-picker-label {
    padding: $padding;
  }

  .ql-toolbar button,
  .ql-toolbar .ql-color-picker {
    display: inline-flex;
    width: unset;
    height: unset;
    cursor: pointer;
  }
}

@mixin toolbar-action-hover($background: null, $border-radius: null) {
  .ql-toolbar button,
  .ql-toolbar .ql-color-picker,
  .ql-toolbar .ql-picker {
    &:hover {
      background-color: $background;
      border-radius: $border-radius;
    }
  }
}

@mixin toolbar-action-picker-label() {
  .ql-picker .ql-picker-label {
    border: unset;
    cursor: inherit;
  }
}

@mixin toolbar-action-box($margin: null) {
  .ql-toolbar.ql-snow .ql-formats {
    margin: $margin;
  }
}

@mixin content($border: null, $border-width: null, $font-size: null) {
  font-size: $font-size;

  &.ql-snow {
    border: $border;
    border-width: $border-width;
  }
}
