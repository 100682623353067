$red: #e24c4c;

$blue-100: #eff3f8;
$blue-500: #3b82f6;
$blue-700: #007aaa;

$green: #22c55e;

$orange: #f59e0b;

$dark-500: #6c757d;
$dark-700: #495057;

$gray-100: #f8f9fa;
$gray-200: #efefef;
$gray-300: #ced4da;

$white: #ffffff;
