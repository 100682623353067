@use 'src/styles/variables/sizes';

@mixin content($title-font-size) {
  .confirmation {
    &__title {
      font-size: $title-font-size;
      margin-right: sizes.$size-48;
      font-weight: bold;
    }
  }
}
