@use 'src/styles/mixins/panel-menus';

$_item-border-radius: 6px !default;
$_item-outline-color: #bfdbfe !default;
$_item-text-color: #3c3c44 !default;
$_item-text-highlight-color: $_item-text-color !default;
$_item-text-hover-color: #343a40 !default;
$_item-padding: 1rem !default;

.p-panelmenu {
  @include panel-menus.content(
    $item-border-radius: $_item-border-radius,
    $item-outline-color: $_item-outline-color,
    $item-text-color: $_item-text-color,
    $item-text-highlight-color: $_item-text-highlight-color,
    $item-text-hover-color: $_item-text-hover-color,
    $item-padding: $_item-padding
  );
}
