$size-4: 0.25rem;
$size-6: 0.375rem;
$size-8: 0.5rem;
$size-12: 0.75rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-24: 1.5rem;
$size-28: 1.75rem;
$size-32: 2rem;
$size-48: 3rem;
$size-128: 8rem;

$sidebar-width: 16rem;
$navbar-height: 4.375rem;
