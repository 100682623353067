@use 'src/styles/variables/sizes';

@forward 'buttons';
@forward 'cards';
@forward 'confirmation-dialogs';
@forward 'panel-menus';
@forward 'scaffolds';
@forward 'tables';
@forward 'menubar';
@forward 'dropdowns';
@forward 'overlay-panels';
@forward 'editor';

$default-component-font-size: sizes.$size-14 !default;

.p-component {
  font-size: $default-component-font-size;
}
