@forward 'styles/libs/bootstrap';
@forward 'styles/libs/bootstrap-icons';
@forward 'styles/libs/primeng';
@forward 'styles/libs/quill';

@forward 'styles/fonts';
@forward 'styles/components';

@use 'src/styles/variables/colors';

$body-text-color: colors.$dark-700;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: $body-text-color;
}

* {
  font-family: Montserrat, sans-serif;
}
