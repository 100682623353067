@mixin placeholder($font-size) {
  .p-dropdown-label.p-placeholder {
    font-size: $font-size;
  }
}

@mixin label($font-size) {
  .p-dropdown-label {
    font-size: $font-size;
  }
}
