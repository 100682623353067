@use 'src/styles/mixins/buttons';
@use 'src/styles/variables/sizes';

$_icon-size: sizes.$size-16 !default;
$_button-padding: sizes.$size-12 !default;
$_button-label-with-icon-space-between: sizes.$size-8 !default;

.p-button {
  @include buttons.content($padding: $_button-padding);
  @include buttons.icon($size: $_icon-size);
  @include buttons.label();

  &.p-button-icon-with-label {
    @include buttons.icon-with-label(
      $space: $_button-label-with-icon-space-between
    );
  }
}
