@mixin body($background-color, $background-color-hover) {
  .p-datatable-tbody > tr {
    td {
      background-color: white;
    }

    &:hover {
      td {
        background-color: $background-color-hover;
      }
    }
  }
}

@mixin sortable-cells() {
  .p-datatable-thead > tr > th {
    &.p-sortable-column {
      .p-sortable-column {
        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

@mixin header-cells($font-size) {
  .p-datatable-thead > tr > th {
    font-size: $font-size;
  }
}

@mixin action-cells($action-button-padding) {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    &.column-type-actions {
      position: sticky;
      right: 0;
      text-align: end;

      .p-button {
        padding: $action-button-padding;
      }
    }
  }
}

@mixin select-cells() {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    &.column-type-select {
      text-align: center;
    }
  }
}
