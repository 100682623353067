@mixin content($padding) {
  .p-overlaypanel-content {
    padding: $padding;

    .p-menu {
      padding: unset;
      background: unset;
      border: unset;
      border-radius: unset;
    }
  }
}
