@mixin content($padding) {
  padding: $padding;
}

@mixin label() {
  .p-button-label {
    font-weight: 500;
  }
}

@mixin icon($size) {
  .p-button-icon {
    font-size: $size;
  }
}

@mixin icon-with-label($space) {
  .p-button-label {
    margin-left: $space;
  }
}
